.users {
  max-width: 1200px;
  min-height: 50vh;
  width: 70%;
  margin: 0 auto;
  padding: 20px 15px;
  &__actions {
    display: flex;
    width: 100%;
    justify-content: space-between;
    h2 {
      font-family: $primary_font;
      text-transform: uppercase;
      color: $txt_color;
    }
    .add-user {
      display: flex;
    }
  }
  p {
    font-family: $primary_font;
    font-size: 18px;
  }
  table {
    width: 100%;
    margin: 20px 0;
    font-family: $primary_font;
    text-transform: uppercase;
    border-collapse: collapse;
    thead > tr {
      border-bottom: 1px solid $secondary_color;
    }
    tbody > tr {
      border-bottom: 1px solid rgba($color: $secondary_color, $alpha: 0.2);
    }
    tr {
      height: 40px;
      td:nth-of-type(2),
      td:nth-of-type(3) {
        width: 100px;
      }
    }
    th {
      text-align: left;
    }
  }
  .btn {
    text-decoration: none;
    @include btn;

    &.delete {
      @include btn($color: $red);
    }
    &.return {
      @include btn($color: transparent);
      border: 1px solid $secondary_color;
      color: $secondary_color;
      float: right;
      margin-left: 15px;
    }
  }
}
