@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap");
$primary_font: "Roboto Condensed", sans-serif;
$primary_color: #9cd3f7;
$secondary_color: #001863;
$red: #cb3234;
$txt_color: #202020;
$white: #ffffff;

@mixin btn($color: $primary_color) {
  font-family: $primary_font;
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  background-color: $color;
  border: none;
  color: $white;
  padding: 5px 25px;
  margin: 15px 0;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: 0 0.7em 0.5em -0.4em $secondary_color;
    transform: translateY(-0.25em);
  }
}
