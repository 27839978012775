@import "./spinner";
@import "./variables.scss";
@import "./header";
@import "./footer";
@import "./users";
@import "./app";
@import "./home";
body {
  margin: 0;
  padding: 0;
}
