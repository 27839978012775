.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  padding: 20px;
  box-sizing: border-box;
  a {
    display: flex;
    text-decoration: none;
    img {
      width: 50px;
      height: 50px;
      margin: 5px 10px;
    }
    h1 {
      font-family: $primary_font;
      text-transform: uppercase;
      color: $txt_color;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
