.footer {
  padding: 20px;
  text-align: center;
  background-color: $primary_color;
  box-sizing: border-box;
  span {
    font-family: $primary_font;
    color: $white;
    font-size: 18px;
    line-height: 1;
    text-transform: uppercase;
    a {
      color: $white;
      text-decoration: none;
      padding: 0 5px;
      color: rgba($color: $secondary_color, $alpha: 0.5);
      &:hover {
        color: rgba($color: $secondary_color, $alpha: 0.7);
      }
    }
  }
}
